<script setup>
const emits = defineEmits(['onchange', 'max'])
const props = defineProps({
  value: {
    type: Number,
    default: 1
  },
  min: {
    type: Number,
    default: 1
  },
  max: {
    type: Number,
    default: 999
  },
  disabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: String
  },
  loading: {
    type: Boolean,
    default: false
  }
})
const c = useCartStore()
const quantity = ref(props.value)

watch(quantity, (x) => {
  c.useKey(props.id)
  emits('onchange', x)
  emits('max', false)
})
watch(() => props.value, x => quantity.value = x)

const keydown = (event) => {
  const codes = [
    190, // .
    69 // e (scientific notaion, 1e2 === 100)
  ]
  if (codes.includes(event.keyCode)) {
    event.preventDefault()
    return false
  }
  return true
}
const add = () => {
  if (quantity.value < props.max) {
    quantity.value++
  }
  else {
    emits('max', true)
  }
}
</script>

<template>
  <div :class="['quantity', { disabled }]">
    <div
      :class="['remove', { dis: quantity === 1 }]"
      @click.stop="quantity > min ? quantity-- : null"
    >
      <svg
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
      >
        <path
          fill="currentColor"
          d="M200-440v-80h560v80H200Z"
        />
      </svg>
    </div>
    <div
      v-if="c.key === id && loading"
      class="cnt"
    >
      <vSpinner :size="24" />
    </div>
    <input
      v-else
      v-model="quantity"
      type="number"
      pattern="^\d+$"
      :min="min"
      :max="max"
      step="1"
      aria-label="Količina"
      @keydown.space.stop="keydown"
    >
    <div
      :class="['add', { dis: max === quantity }]"
      @click.stop="add"
    >
      <svg
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
      >
        <path
          fill="currentColor"
          d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
        />
      </svg>
    </div>
  </div>
</template>

<style scoped>
.quantity {
  --size: 36px;
  border: 1px solid rgb(var(--color-rgb) / .25);
  display: inline-grid;
  grid-template-columns: var(--size) auto var(--size);
  height: var(--size);
  align-items: center;
  border-radius: calc(var(--size) / 2);
  user-select: none;

  &.disabled {
    pointer-events: none;
    color: rgb(var(--color-rgb) / .25);
  }
}

input {
  width: 3ch;
  text-align: center;
  font-size: 16px;
  font-weight: 800;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.add,
.remove {
  height: 100%;
  display: grid;
  place-items: center;

  & svg {
    vertical-align: middle;
  }

  &:active {
    transform: scale(.8);
  }
}

.dis {
  color: rgb(var(--color-rgb) / .25);
}

.cnt {
  text-align: center;
}
</style>
